<template>
  <v-container fluid>
    <v-row no-gutters align='center' justify='center'>
      <div class='text-h5'>
        {{$t('USERS')}}
      </div>

      <v-btn icon class='ml-3 mt-1' @click='getData'>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>

      <v-spacer />

      <v-btn text @click='detailsDialog = true'>
        <v-icon class='mr-2'>
          mdi-account-plus-outline
        </v-icon>
        {{$t('NEW_USER')}}
      </v-btn>
    </v-row>
    <v-divider class='my-3' />

    <v-row no-gutters class='mb-3'>
      <v-list-item-subtitle>
        {{$t('USERS_PAGE_MEANING')}}
      </v-list-item-subtitle>
    </v-row>

    <v-row no-gutters>
      <v-card flat class='wd-100'>
        <v-card-title class='py-4'>
          <v-spacer />

          <v-text-field
            v-model='search'
            append-icon='mdi-magnify'
            :label='$t("SEARCH")'
            single-line
            hide-details
            clearable
          />
        </v-card-title>

        <v-data-table
          class='wd-100'
          multi-sort
          :search='search'
          :headers='userTableHeaders'
          :items='list'
          :item-class='(item) => getTableRowClass(item)'
          :footer-props='{
            itemsPerPageOptions: [50, 100, 300, -1]
          }'
        >
          <template #item.icon>
            <v-avatar size='40' color='primary lighten-2'>
              <v-icon dark>
                mdi-account
              </v-icon>
            </v-avatar>
          </template>

          <template #item.name='{ item }'>
            <v-list-item class='pa-0'>
              <v-list-item-content>
                <v-list-item-title>
                  {{item.name}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{getRole(item)}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template #item.creation_date='{ item }'>
            <v-tooltip top>
              <template #activator='{ on }'>
                <div v-on='on'>
                  {{formattedDate(item.creation_date)}}
                </div>
              </template>
              {{formattedDateInWords(item.creation_date)}}
            </v-tooltip>
          </template>

          <template #item.last_login='{ item }'>
            <v-tooltip top>
              <template #activator='{ on }'>
                <div v-on='on'>
                  {{item.last_login ? formattedDateInWords(item.last_login) : '-'}}
                </div>
              </template>
              {{item.last_login ? formattedDate(item.last_login) : '-'}}
            </v-tooltip>
          </template>

          <template #item.actions='{ item }'>
            <v-menu
              v-if='!item.role.isSuperAdmin'
              bottom
              left
            >
              <template #activator='{ on, attrs }'>
                <v-btn
                  icon
                  v-bind='attrs'
                  v-on='on'
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list class='pa-0'>
                <v-list-item v-if='item.account_status_id === 3' @click='openUserAccessDataDialog(item)'>
                  <v-list-item-icon class='mr-3'>
                    <v-icon>mdi-lock-reset</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{$t('CHANGE_USER_ACCESS_DATA')}}</v-list-item-title>
                </v-list-item>

                <v-list-item v-if='item.account_status_id === 1 && getUser.account.id !== item.account_id' :input-value='true' color='error' @click='disableUser(item)'>
                  <v-list-item-icon class='mr-3'>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{$t('DISABLE_USER')}}</v-list-item-title>
                </v-list-item>

                <v-list-item v-if='item.account_status_id === 2 && getUser.account.id !== item.account_id' @click='reactivateUser(item)'>
                  <v-list-item-icon class='mr-3'>
                    <v-icon>mdi-check-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{$t('REACTIVATE_USER')}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-row>

    <!-- ---------------------------------------------------------------- -->
    <!-- DIALOGS -->
    <!-- ---------------------------------------------------------------- -->
    <v-dialog v-model='detailsDialog' fullscreen persistent>
      <v-card v-if='detailsDialog' style='background-color: var(--v-background-base);'>
        <v-container fluid class='pa-0'>
          <v-app-bar dark color='primary'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click.native='detailsDialog = false'>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{$t('CLOSE')}}</span>
            </v-tooltip>

            <div class='text-h5 ml-2'>
              {{$t('USER')}}
            </div>
          </v-app-bar>

          <users-details @new-user='getData' @close='detailsDialog = false' />
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model='userAccessDataDialog' fullscreen persistent>
      <v-card v-if='userAccessDataDialog' style='background-color: var(--v-background-base);'>
        <v-container fluid class='pa-0'>
          <v-app-bar dark color='primary'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click.native='userAccessDataDialog = false'>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{$t('CLOSE')}}</span>
            </v-tooltip>

            <div class='text-h5 ml-2'>
              {{$t('CHANGE_USER_ACCESS_DATA')}}
            </div>
          </v-app-bar>

          <users-access-data :account-id='selectedUser.account_id' @close='closeUserAccessDataDialog()' />
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { format, parseISO, formatDistanceToNow } from 'date-fns';
  import ptBr from 'date-fns/locale/pt-BR';
  import { mapGetters } from 'vuex';
  import Swal from 'sweetalert2';

  export default {
    name: 'Users',
    components: {
      UsersDetails: () => import('./UsersDetails'),
      UsersAccessData: () => import('./UsersAccessData'),
    },
    data: function () {
      return {
        list: undefined,
        search: undefined,
        userTableHeaders: [
          {
            text: '',
            sortable: false,
            value: 'icon',
            width: '30',
            align: 'center',
          },
          { text: this.$t('NAME'), value: 'name' },
          { text: this.$t('CREATED_BY'), value: 'account_creator_name' },
          { text: this.$t('CREATION_DATE'), value: 'creation_date' },
          { text: this.$t('LAST_LOGIN'), value: 'last_login' },
          { text: this.$t('STATUS'), value: 'account_status_description' },
          {
            text: '',
            sortable: false,
            value: 'actions',
            width: '30',
            align: 'center',
          },
        ],
        detailsDialog: false,
        userAccessDataDialog: false,
        selectedUser: undefined,
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
    },
    mounted: function () {
      this.getData();
      if (this.getUser.account.role.isSuperAdmin) {
        this.userTableHeaders.splice(2, 0, { text: this.$t('CUSTOMER'), value: 'customer_name' });
      }
    },
    methods: {
      openUserAccessDataDialog: function (item) {
        this.selectedUser = item;
        this.userAccessDataDialog = true;
      },
      closeUserAccessDataDialog: function () {
        this.userAccessDataDialog = false;
        this.selectedUser = undefined;
      },
      formattedDate: function (value) {
        return format(parseISO(value), 'dd/MM/yyyy HH:mm');
      },
      formattedDateInWords: function (value) {
        return formatDistanceToNow(parseISO(value), { locale: ptBr });
      },
      getTableRowClass: function (item) {
        if (item.account_status_id === 3) {
          return 'grey lighten-2 grey--text text--darken-1';
        }

        if (item.account_status_id === 2) {
          return 'red lighten-3 grey--text text--darken-2';
        }

        return '';
      },
      getRole: function (item) {
        if (item.role.isSuperAdmin) {
          return this.$t('SUPER_ADMIN');
        }
        if (item.role.isAdmin) {
          return this.$t('ADMIN');
        }
        if (item.role.isSupevisor) {
          return this.$t('SUPERVISOR');
        }
        return this.$t('USER');
      },
      getData: async function () {
        try {
          const { data } = await axios({
            url: '/account-get-all',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.list = data && data.list;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      disableUser: async function (item) {
        try {
          const result = await Swal.fire({
            title: this.$t('WARNING'),
            text: this.$t('REALLY_WANT_DISABLE_USER_ACCOUNT', { name: item.name }),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.$t('CANCEL'),
            confirmButtonText: this.$t('CONFIRM'),
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          });

          if (!result.value) {
            return;
          }

          await axios({
            url: '/account-disable',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              accountId: item.account_id,
            },
          });

          this.getData();
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      reactivateUser: async function (item) {
        try {
          const result = await Swal.fire({
            title: this.$t('WARNING'),
            text: this.$t('REALLY_WANT_REACTIVATE_USER_ACCOUNT', { name: item.name }),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.$t('CANCEL'),
            confirmButtonText: this.$t('CONFIRM'),
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          });

          if (!result.value) {
            return;
          }

          await axios({
            url: '/account-reactivate',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              accountId: item.account_id,
            },
          });

          this.getData();
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
