const imageType = [
  { RAW: 'raw' },
  { PNG: 'png' },
  { JPEG: 'jpeg' },
];

const penColor = [
  { GRAY: 1 },
  { BLACK: 2 },
  { BLUE: 3 },
  { RED: 5 },
  { GREEN: 7 },
];

const penIntensity = 1;
const penWidth = 30;

export default {
  imageType,
  penColor,
  penIntensity,
  penWidth,
};
