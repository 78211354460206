<template>
  <v-container fill-height fluid>
    <v-col v-for='key in Object.keys(moduleConfigs)' :key='key' cols='3' class='pa-4'>
      <v-text-field
        v-if='typeof moduleConfigs[key] === "number" || typeof moduleConfigs[key] === "string"'
        v-model='moduleConfigs[key]'
        :label='$t(key)'
        type='number'
        @input='setValue(key)'
      />
      <v-checkbox
        v-else-if='typeof moduleConfigs[key] === "boolean"'
        v-model='moduleConfigs[key]'
        :label='$t(key)'
        color='secondary'
        @change='setValue(key)'
      />
      <v-autocomplete
        v-else
        v-model='moduleConfigs[key].value'
        :label='$t(key)'
        :items='parseArray(moduleConfigs[key])'
        @input='setValue(key)'
      />
    </v-col>
    <slot />
  </v-container>
</template>

<script>
  import moduleOptions from './utils/configs';

  export default {
    name: 'CustomerCaptureModuleConfigs',
    props: {
      configs: {
        type: Object,
        // eslint-disable-next-line
        default: () => {},
      },
      selectedModule: {
        type: String,
        default: '',
      },
    },
    computed: {
      moduleConfigs: function () {
        switch (this.selectedModule) {
          case 'face':
          case 'mugshot': return this.faceOptions;
          case 'finger': return this.fingerOptions;
          case 'signature': return this.signatureOptions;
          case 'palm': return this.palmOptions;
          default: return [{}];
        }
      },
    },
    data: function () {
      return {
        faceOptions: moduleOptions.faceOptions,
        fingerOptions: moduleOptions.fingerOptions,
        palmOptions: moduleOptions.palmOptions,
        signatureOptions: moduleOptions.signatureOptions,
      };
    },
    mounted: function () {
      this.$nextTick(() => {
        setTimeout(this.updateWithCustomerData, 100);
      });
    },
    methods: {
      parseArray: function (array) {
        if (Array.isArray(array)) {
          return array.map((item) => {
            const key = Object.keys(item)[0];
            return {
              text: this.$t(key) || key,
              value: item[key],
            };
          });
        }
        return undefined;
      },
      updateWithCustomerData: function () {
        if (this.configs) {
          for (let category in this.configs) { // eslint-disable-line
            let target;
            if (category === 'face') {
              target = this.faceOptions;
            } else if (category === 'finger') {
              target = this.fingerOptions;
            } else if (category === 'palm') {
              target = this.palmOptions;
            } else {
              target = this.signatureOptions;
            }
            for (let key in this.configs[category]) { // eslint-disable-line
              if (typeof target[key] === 'object') {
                target[key].value = this.configs[category][key];
              } else {
                target[key] = this.configs[category][key];
              }
            }
          }
          this.$forceUpdate();
        }
      },
      setValue: function (key) {
        if (!this.configs[this.selectedModule]) {
          this.configs[this.selectedModule] = {};
        }
        this.configs[this.selectedModule][key] = this.moduleConfigs[key].hasOwnProperty('value') ? this.moduleConfigs[key].value : this.moduleConfigs[key]; // eslint-disable-line
        this.$emit('updated-configs', this.configs);
      },
    },
  };
</script>

<style scoped>
</style>
