<template>
  <v-container fill-height fluid style='height: 90vh'>
    <v-row no-gutters align='center' justify='center'>
      <v-card tile flat :width='700' color='white'>
        <v-row no-gutters align='center' justify='center'>
          <v-card-text>
            <v-form>
              <v-radio-group v-model='dateType' row>
                <v-radio
                  :label='$t("MONTHLY")'
                  color='secondary'
                  value='M'
                />
                <v-radio
                  :label='$t("PERIOD")'
                  color='secondary'
                  value='P'
                />
              </v-radio-group>
              <v-row>
                <v-row v-if='dateType === "M"' class='ma-4'>
                  <v-autocomplete
                    v-model='dateFilter.month'
                    :label='$t("MONTH")'
                    :items='monthList'
                    item-text='text'
                    item-value='value'
                    return-object
                    class='mr-2'
                    @change='setDates'
                  />
                  <v-autocomplete
                    v-model='dateFilter.year'
                    :label='$t("YEAR")'
                    :items='yearList'
                    class='ml-2'
                    @change='setDates'
                  />
                </v-row>
                <v-row v-else-if='dateType === "P"' class='ma-4'>
                  <v-text-field
                    v-model='dateFilter.initial'
                    type='date'
                    :label='$t("INITIAL_DATE")'
                    hide-details
                    class='mr-5'
                  />
                  <v-text-field
                    v-model='dateFilter.final'
                    type='date'
                    :label='$t("END_DATE")'
                    hide-details
                    class='ml-5'
                  />
                </v-row>
              </v-row>
              <v-checkbox
                v-model='sendByEmail'
                color='secondary'
                :label='$t("SEND_BY_EMAIL")'
                hide-details
              />
              <div v-if='sendByEmail' class='mt-2'>
                <v-chip
                  v-for='(listedEmail, i) in emailList'
                  :key='i'
                  :color='listedEmail.color'
                  close
                  class='mr-1'
                  @click:close='emailList.splice(i, 1)'
                >
                  {{ listedEmail.address }}
                </v-chip>
                <v-text-field
                  v-model='email'
                  :hint='$t("EMAIL_LIST_INSERTION_HINT")'
                  persistent-hint
                  append-outer-icon='mdi-plus'
                  @click:append-outer='onEmailInput'
                  @keyup='onEmailInput'
                />
              </div>
            </v-form>
          </v-card-text>

          <v-card-actions class='wd-100 mb-1'>
            <v-btn depressed block color='secondary' @click='checkInvalidEmail'>
              {{ $t('EXPORT') }}
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { startOfMonth, endOfMonth, format } from 'date-fns';
  import Swal from 'sweetalert2';
  import { validateEmail } from '../../util/validation/email';

  export default {
    name: 'RecordReusageReport',
    computed: {
      currentMonth: function () {
        const currentMonth = new Date().getMonth() + 1;
        return this.monthList.find((month) => month.value === currentMonth);
      },
      monthList: function () {
        return [
          { text: this.$t('JANUARY'), value: 1 },
          { text: this.$t('FEBRUARY'), value: 2 },
          { text: this.$t('MARCH'), value: 3 },
          { text: this.$t('APRIL'), value: 4 },
          { text: this.$t('MAY'), value: 5 },
          { text: this.$t('JUNE'), value: 6 },
          { text: this.$t('JULY'), value: 7 },
          { text: this.$t('AUGUST'), value: 8 },
          { text: this.$t('SEPTEMBER'), value: 9 },
          { text: this.$t('OCTOBER'), value: 10 },
          { text: this.$t('NOVEMBER'), value: 11 },
          { text: this.$t('DECEMBER'), value: 12 },
        ];
      },
      yearList: function () {
        return Array.from({ length: 51 }, (_, i) => new Date().getFullYear() - i);
      },
    },
    data: function () {
      return {
        email: '',
        sendByEmail: false,
        emailList: [],
        dateType: 'M',
        dateFilter: {
          initial: '',
          final: '',
          month: this.currentMonth,
          year: new Date().getFullYear(),
        },
      };
    },
    mounted: function () {
      this.setDates();
    },
    methods: {
      setDates: function () {
        if (!this.dateFilter.month) {
          this.dateFilter.month = this.currentMonth;
        }
        const date = new Date();
        date.setMonth(this.dateFilter.month.value - 1);
        date.setFullYear(this.dateFilter.year);
        this.dateFilter.initial = format(startOfMonth(date), 'yyyy-MM-dd');
        this.dateFilter.final = format(endOfMonth(date), 'yyyy-MM-dd');
      },
      onEmailInput: function ($event) {
        if (($event.type === 'click' || $event.code === 'Enter' || $event.code === 'Comma') && this.email) {
          const list = this.email.split(',').filter((el) => el);
          const emailList = [];
          list.forEach((email) => {
            emailList.push({
              address: email,
              color: validateEmail(email) ? 'success' : 'error',
            });
          });

          this.emailList = this.emailList.concat(emailList);
          this.email = '';
        }
      },
      checkInvalidEmail: function () {
        if (this.sendByEmail && this.emailList.find((email) => email.color === 'error')) {
          Swal.fire({
            icon: 'warning',
            title: this.$t('ATTENTION'),
            text: this.$t('THERES_INVALID_EMAIL_ON_LIST'),
            showCancelButton: true,
            cancelButtonText: this.$t('CANCEL'),
            confirmButtonText: this.$t('CONFIRM'),
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          }).then((result) => {
            if (result.value) {
              this.exportReport();
            }
          });
        } else {
          this.exportReport();
        }
      },
      exportReport: async function () {
        const date = new Date();
        date.setFullYear(this.dateFilter.year);
        date.setMonth(this.dateFilter.month.value - 1);

        const body = {
          initialDate: this.dateFilter.initial,
          finalDate: this.dateFilter.final,
          emailList: this.sendByEmail ? this.emailList.map((item) => item.address) : undefined,
        };

        const { data } = await axios({
          url: '/record-reusage-report',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'POST',
          data: body,
          withCredentials: true,
        });

        if (data && !data.messages) {
          if (!this.sendByEmail) {
            const file = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = file;
            link.setAttribute('download', `relatorio_importacao_${body.initialDate}_${body.finalDate}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            Swal.fire(this.$t('EMAIL_SENT_SUCCESSFULLY'), '', 'success');
          }
        }
      },
    },
  };
</script>
