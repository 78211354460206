<template>
  <v-container fill-height fluid style='height: 90vh'>
    <v-row no-gutters align='center' justify='center'>
      <v-card tile flat :width='700' color='white'>
        <v-row no-gutters align='center' justify='center'>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model='cpf'
                outlined
                dense
                clearable
                :label='$t("CPF")'
                hide-details
                maxlength='11'
              />
              <v-text-field
                v-model='renach'
                outlined
                dense
                clearable
                :label='$t("RENACH")'
                hide-details
                class='mt-4'
                maxlength='11'
              />
              <v-checkbox
                v-model='sendToBCA'
                color='secondary'
                label='Enviar para BCA'
                hide-details
              />
            </v-form>
          </v-card-text>

          <v-card-actions class='wd-100'>
            <v-btn depressed block color='secondary' @click='AdjustEntries'>
              {{ $t('CORRECT_ENTRIES') }}
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { showError, showSuccess } from '../../util/notification';

  export default {
    name: 'AdjustmentDuplicateEntries',
    data: function () {
      return {
        cpf: undefined,
        renach: undefined,
        sendToBCA: true,
      };
    },
    methods: {
      AdjustEntries: async function () {
        if (!this.cpf || !this.renach) {
          showError({
            message: this.$t('YOU_MUST_INFORM_CPF_RENACH_LAST_RENACH'),
          });
          return;
        }

        if (this.sendToBCA) {
          await Swal.fire({
            title: 'Deseja enviar para BCA ?',
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.$t('YES'),
            cancelButtonText: this.$t('NO'),
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          }).then((result) => {
            if (!result.value) {
              this.sendToBCA = false;
            }
          });
        }

        try {
          const data = await axios({
            url: '/remove-duplicate-record',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            params: {
              cpf: this.cpf,
              renach: this.renach,
              sendToBCA: this.sendToBCA,
            },
          });
          if (data.status === 200) {
            showSuccess({ message: this.$t('SUCCESS_IN_ADJUSTING_DUPLICATE_ENTRIES') });
          } else {
            showError({ message: this.$t('ERROR_CORRECTING_DUPLICATE_ENTRIES') });
          }
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
