const allowManualSave = [
  { OFF: 0 },
  { ON: 1 },
];

const repetitionControl = [
  { OFF: 0 },
  { ON: 1 },
];

const flowType = [
  { FLOW_TYPE_TEN_FLAT_CAPTURES: 0 },
  { FLOW_TYPE_TEN_ROLLED_CAPTURES: 1 },
  { FLOW_TYPE_FIVE_FLAT_CAPTURES: 2 },
  { FLOW_TYPE_THREE_FLAT_CAPTURES: 3 },
  { FLOW_TYPE_SEQUENCE_CONTROL_CAPTURE: 4 },
  { FLOW_TYPE_TWO_FLAT_SEQUENCE_CONTROL_CAPTURE: 5 },
  { FLOW_TYPE_FOUR_FLAT_SEQUENCE_CONTROL_CAPTURE: 6 },
  { FLOW_TYPE_PINCH: 7 },
];

const maxNfiqScore = [
  { 1: 1 },
  { 2: 2 },
  { 3: 3 },
  { 4: 4 },
  { 5: 5 },
];

export default {
  flowType,
  repetitionControl,
  allowManualSave,
  maxNfiqScore,
};
