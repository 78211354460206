const shutterSpeed = [
  { Off: 0 },
  { '13S': 1 },
  { '10S': 2 },
  { '8S': 3 },
  { '6S': 4 },
  { '5S': 5 },
  { '4S': 6 },
  { '3S2': 7 },
  { '2S5': 8 },
  { '2S': 9 },
  { '1S6': 10 },
  { '1S3': 11 },
  { '1S': 12 },
  { '0S8': 13 },
  { '0S6': 14 },
  { '0S5': 15 },
  { '0S4': 16 },
  { '0S3': 17 },
  { '1/4S': 18 },
  { '1/5S': 19 },
  { '1/6S': 20 },
  { '1/8S': 21 },
  { '1/10S': 22 },
  { '1/13S': 23 },
  { '1/15S': 24 },
  { '1/20S': 25 },
  { '1/25S': 26 },
  { '1/30S': 27 },
  { '1/40S': 28 },
  { '1/50S': 29 },
  { '1/60S': 30 },
  { '1/80S': 31 },
  { '1/100S': 32 },
  { '1/125S': 33 },
  { '1/160S': 34 },
  { '1/200S': 35 },
  { '1/250S': 36 },
  { '1/320S': 37 },
  { '1/400S': 38 },
  { '1/500S': 39 },
  { '1/640S': 40 },
  { '1/800S': 41 },
  { '1/1000S': 42 },
  { '1/1250S': 43 },
  { '1/1600S': 44 },
  { '1/2000S': 45 },
  { '1/2500S': 46 },
  { 'AUTO SHUTTER': 47 },
];

const isoValue = [
  { Off: 0 },
  { 80: 1 },
  { 100: 2 },
  { 125: 3 },
  { 160: 4 },
  { 200: 5 },
  { 250: 6 },
  { 320: 7 },
  { 400: 8 },
  { 500: 9 },
  { 640: 10 },
  { 800: 11 },
  { 1000: 12 },
  { 1250: 13 },
  { 1600: 14 },
  { 2000: 15 },
  { 2500: 16 },
  { 3200: 17 },
  { 4000: 18 },
  { 4800: 19 },
  { 6400: 20 },
  { 8000: 21 },
  { 9600: 22 },
  { 11200: 23 },
  { AUTO: 24 },
];

const whiteBalance = [
  { DAYLIGHT: 1 },
  { CLOUDY: 2 },
  { TUNGSTEN: 3 },
  { XENON_FLASH: 4 },
  { HALOGEN: 5 },
  { FLUORESCENT: 6 },
  { FLUORESCENT_H: 7 },
  { CUSTOM: 8 },
  { AKIYAMA: 9 },
];

const flashProperty = [
  { OFF: 0 },
  { ON: 1 },
];

const imageFilter = [
  { OFF: 0 },
  { ON: 1 },
];

const imageType = [
  { RAW: 0 },
  { PNG: 1 },
  { JPEG: 2 },
];

const flashWidth = [
  { LOW: 300 },
  { MID: 700 },
  { HIGH: 1000 },
  { MAX: 1300 },
];

const aperture = [
  { 'f/3.4': 0 },
  { 'f/4.0': 1 },
  { 'f/4.5': 2 },
  { 'f/5.0': 3 },
  { 'f/5.6': 4 },
  { 'f/6.3': 5 },
  { 'f/7.1': 6 },
  { 'f/8.0': 7 },
];

const ringlight = [
  { OFF: 0 },
  { ON: 1 },
];

const preset = [
  { Padrão: 1 },
  { 'Preset 1': 2 },
  { 'Preset 2': 3 },
  { 'Preset 3': 4 },
  { 'Preset 4': 5 },
];

const segmentation = true;
const autoCapture = false;

export default {
  shutterSpeed,
  isoValue,
  whiteBalance,
  flashProperty,
  imageType,
  flashWidth,
  aperture,
  preset,
  imageFilter,
  ringlight,
  segmentation,
  autoCapture,
};
