import faceOptions from './face';
import fingerOptions from './finger';
import palmOptions from './palm';
import signatureOptions from './signature';

export default {
  faceOptions,
  fingerOptions,
  palmOptions,
  signatureOptions,
};
