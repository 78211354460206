<template>
  <v-container fluid>
    <v-card>
      <v-col cols='12' class='d-flex justify-end align-center pt-12'>
        <v-btn
          outlined
          color='warning'
          elevation='0'
          @click='recoverRecords'
        >
          {{ $t('RECOVER_RECORDS') }}
        </v-btn>
        <v-icon class='ml-16 mr-16' color='primary' @click.stop='openModal'>mdi-cog</v-icon>
      </v-col>
      <v-card-title class='py-4'>
        <v-row class='mt-4 mb-8 d-flex align-center'>
          <v-col cols='5'>
            <v-text-field
              v-model='search'
              class='ml-8'
              append-icon='mdi-magnify'
              :label='$t("SEARCH")'
              single-line
              hide-details
              clearable
            />
          </v-col>
          <v-row class='d-flex justify-start align-center'>
            <v-col cols='12' class='ml-16'>
              <v-row class='mt-4 d-flex justify-start align-center'>
                <span>{{ $t('FROM') }}: </span>
                <CustomDatePicker class='pt-0' @change='setStartDate' />
              </v-row>
            </v-col>
          </v-row>
          <v-row class='d-flex justify-start align-center'>
            <v-col cols='12'>
              <v-row class='d-flex align-center'>
                <span>{{ $t('UNTIL') }}: </span>
                <CustomDatePicker class='pt-0' @change='setEndDate' />
              </v-row>
            </v-col>
          </v-row>
        </v-row>
      </v-card-title>
      <v-data-table
        v-model='selected'
        item-key='id'
        class='wd-100'
        show-select
        multi-sort
        :search='search'
        :headers='header'
        :items='list'
        :footer-props='{
          itemsPerPageOptions: [30, 50, 100, 300, -1]
        }'
      />
    </v-card>
    <v-dialog v-model='showModal' max-width='800px' max-height='800px'>
      <v-card>
        <v-card-title class='headline color-primary d-flex align-center' style='margin-bottom: 16px;'>
          <v-icon style='margin-right: 20px; color: var(--v-primary-base)'>mdi-cog</v-icon>
          <span>Configurações</span>
        </v-card-title>
        <v-divider />
        <v-card-text style='margin-top: 20px'>
          <span>Configure o limite de dias para os cadastros serem excluídos definitivamente. Após este prazo não será possível recuperar os cadastros.</span>
          <div class='d-flex justify-center'>
            <v-text-field v-model='configField' type='number' style='max-width: 200px;' label='Limite de dias' />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color='primary' text @click='saveConfig'>Salvar</v-btn>
          <v-btn color='grey' text @click='showModal = false'>Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { mapGetters } from 'vuex';
  import { format, parseISO } from 'date-fns';

  export default {
    components: {
      CustomDatePicker: () => import('../../components/DatePicker/CustomDatePicker.vue'),
    },
    name: 'RecordRecovery',
    data: function () {
      return {
        selected: [],
        list: [],
        startDate: null,
        endDate: null,
        startDateFromPicker: null,
        showStartDatePicker: null,
        endDateFromPicker: null,
        header: [
          { text: this.$t('NAME'), value: 'full_name' },
          { text: this.$t('CPF'), value: 'cpf' },
          { text: this.$t('RENACH'), value: 'renach' },
          { text: this.$t('CREATION_DATE'), value: 'created_at' },
          { text: this.$t('DELETION_DATE'), value: 'deleted_at' },
          { text: this.$t('MESSAGE'), value: 'motive', width: 200 },
        ],
        search: undefined,
        showModal: false,
        configField: '',
      };
    },
    computed: {
      ...mapGetters('auth', ['getUser']),
    },
    methods: {
      openModal () {
        this.showModal = true;
      },
      setStartDate (date) {
        if (date) {
          this.startDateFromPicker = `${date} 00:00:00`;
          this.startDate = `${date} 00:00:00`;
          this.showStartDatePicker = false;
          if (this.endDateFromPicker) {
            this.getDeletedRecords();
          }
        }
      },
      setEndDate (date) {
        if (date) {
          this.endDateFromPicker = `${date} 23:59:59`;
          this.endDate = `${date} 23:59:59`;
          this.showStartDatePicker = false;
          if (this.startDateFromPicker) {
            this.getDeletedRecords();
          }
          this.showEndDatePicker = false;
        }
      },
      formattedDate: function (value) {
        if (!value) return '';
        return format(parseISO(value), 'dd/MM/yyyy');
      },
      formattedList () {
        if (!this.list) {
          return '';
        }
        return this.list.map((item) => ({
          ...item,
          created_at: this.formattedDate(item.created_at),
          deleted_at: item.deleted_at ? this.formattedDate(item.deleted_at) : null,
        }));
      },
      saveConfig () {
        const user = this.getUser;
        Swal.fire({
          title: 'Você tem certeza?',
          text: 'Deseja salvar as alteração no limite de dias para exclusão?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Salvar',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await axios({
                url: `/customer/${user.account.customerId}/configuration/days_limit_exclusion`,
                baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
                method: 'POST',
                responseType: 'json',
                withCredentials: true,
                data: {
                  data: this.configField,
                },
              });
              Swal.fire({
                title: 'Salvo!',
                text: 'A alteração foi salva com sucesso.',
                icon: 'success',
              });
            } catch (error) {
              Swal.fire({
                title: 'Erro!',
                text: 'Ocorreu um erro ao salvar a alteração.',
                icon: 'error',
              });
            }
          }
        });
      },
      recoverRecords: async function () {
        const user = this.getUser;
        const selectedIds = this.selected.map((obj) => obj.id);
        Swal.fire({
          title: 'Você tem certeza?',
          text: 'A exclusão dos cadastros selecionados será revertida. Você deseja continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Salvar',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await axios({
                url: '/entry-update-records',
                baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
                method: 'PUT',
                responseType: 'json',
                withCredentials: true,
                data: {
                  customerAcronym: user.account.customerAcronym,
                  recoverIds: selectedIds,
                },
              });
              Swal.fire({
                title: 'Salvo!',
                text: 'A alteração foi salva com sucesso.',
                icon: 'success',
              }).then(() => {
                window.location.reload();
              });
            } catch (error) {
              Swal.fire({
                title: 'Erro!',
                text: 'Ocorreu um erro ao salvar a alteração.',
                icon: 'error',
              });
            }
          }
        });
      },
      getDeletedRecords: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading', true);
          if (!this.startDate || !this.endDate) {
            const today = new Date();
            this.endDate = `${today.toISOString().split('T')[0]} 23:59:59`;
            today.setDate(today.getDate() - 7); // inicia como filtro padrão de 7 dias
            this.startDate = `${today.toISOString().split('T')[0]} 00:00:00`;
          }
          const { data } = await axios({
            url: '/entry-get-deleted-records',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
            params: {
              endDate: this.endDate,
              startDate: this.startDate,
            },
          });
          this.list = data;
          this.list = this.formattedList();

          this.$store.dispatch('loading/toggleLoading', false);
        } catch (e) {
          this.$store.dispatch('loading/toggleLoading', false);
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
    mounted () {
      this.getDeletedRecords();
    },
  };
</script>
